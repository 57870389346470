/* Accordion.css */
button {
   display: inline-block;
   width: 50vw;
   padding: 20px 35px; /* Adjust padding as needed */
   font-size: 30px; /* Adjust font size as needed */
   font-family: 'textfont';
   text-align: center;
   text-decoration: none;
   border: 1px dotted #069e97; /* Button outline color */
   color: #069e97; /* Button text color */
   border-radius: 0px; /* Rounded corners */
   transition: background-color 0.3s, color 0.3s; /* Smooth transition */
   background-color: transparent; /* Transparent background */
   cursor: pointer;
   position: relative; /* For pseudo-elements */
   overflow: hidden; /* Clip pseudo-elements */
}

p {
   font-family: 'Times New Roman';
   font-size: 20px;
   margin-top: 20px;
}

/* Add the dotted effect on hover */
button:hover::before {
   content: '';
   position: absolute;
   top: 5px; /* Adjust to control the inlay distance */
   left: 5px;
   right: 5px;
   bottom: 5px;
   border: 1.5px dotted #069e97; /* Dotted border color */
   border-radius: 0px; /* Matching the button corners */
   pointer-events: none; /* Prevents pseudo-element from capturing clicks */
   z-index: 1; /* Places the border above the button's background */
}
