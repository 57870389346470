@font-face {
  font-family: 'titlefont';
  src:  url('./fonts/LofiForestTrial-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}  

@font-face {
  font-family: 'subtitlefont';
  src:  url('./fonts/IMFePIit28P.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.name {
  padding-top: 10vh;
  font-family: 'titlefont', sans-serif;
  font-size: 215px;
  color: #B2F8FE;
}

/*the title name for each page*/
.title {
  font-family: 'textfont', sans-serif;
  color: transparent;         /* Make the inside transparent */
  -webkit-text-stroke: 1px #B2F8FE;
}

/* Nav bar titles */
.subtitle {
  font-family: 'subtitlefont', sans-serif;
  color: transparent;         
  -webkit-text-stroke: 1px #C1785F;
  position: relative; 
  padding: 0 .7em; /* Add space for left and right pointers */
  transition: -webkit-text-stroke 0.1s ease;
}

/* Pseudo-elements for the ☞ symbols with outline */
.subtitle::before,
.subtitle::after {
  content: "☞";
  position: absolute;
  color: transparent;              
  -webkit-text-stroke: 1px #C1785F;
  opacity: 0; /* Hide by default */
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease-in-out;
}

.subtitle::before {
  left: -.5em; /* Adjust spacing for left pointer */
  transform: scaleX(1);   
}

.subtitle::after {
  right: -.5em; /* Adjust spacing for right pointer */
  transform: scaleX(-1); /* Flip left pointer */
}

/* Hover effect for solid text and ☞ animation */
.subtitle:hover {
  color: #C1785F;  /* Fill text on hover */
  -webkit-text-stroke: 0; /* Remove outline */
}

.subtitle:hover::before,
.subtitle:hover::after {
  opacity: 1; /* Show both ☞ symbols on hover */
}




@font-face {
  font-family: 'textfont';
  src:         url('./fonts/IMFePIrm28P.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} 

.text {
  font-family: 'Times New Roman', sans-serif;
  position: relative; /* Set relative positioning for the pseudo-element */
}

@font-face {
  font-family: 'textitalicfont';
  src: url('./fonts/IMFePIit28P.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}  

.textitalic {
  font-family: 'Times New Roman', sans-serif;
  font-style: italic;
  position: relative; /* Set relative positioning for the pseudo-element */
}




body {
  font-family: 'textfont', sans-serif;
}


html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

body {
  background-color: #004336;
  font-family: monospace, serif;
}

.home,
.about,
.extra,
.projects,
.app {
  margin: 0 auto;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center horizontally */
  justify-content: flex-start; /* Center vertically */
}

/* Update your existing styles for the navigation bar */
nav ul {
  list-style: none;
  display: flex;
  justify-content: center; /* Center the navigation items horizontally */
}

nav ul li {
  padding: 20px;
  font-size: 30px;
  text-decoration-style: dotted;
}


nav ul li a {
  color: #e5ffff;
  text-decoration: none;
  text-decoration-style: dotted;
}


.current {
  border-bottom: 4px solid white;
}

/*the subtitle text on each page*/
h1 {
  margin-bottom: 20px;
  color: #B2F8FE;
  font-size: 60px;
  text-align: center; /* Center the text horizontally */
}

p {
  margin-bottom: 15px;
  color: #B2F8FE;
  font-size: 20px;
  font-family: monospace;
}

.textfield {
  margin-bottom: 15px;
  color: #e5ffff;
  font-size: 20px;
  font-family: monospace;
  width: 50vw;
}



.about img {
  width: 100%; /* Adjust the width as needed */
  max-width: 270px; /* Set a maximum width to control image size */
  height: auto; /* Maintain aspect ratio */
  margin-top: 20px; /* Add margin for spacing */
  border: 1.5px dotted;
  color:#069e97;
}


/* Styles for the active tab */

.tab:hover {
  text-decoration: underline;
  cursor: pointer;
}



.button {
  display: inline-block;
  padding: 20px 35px;
  font-size: 30px;
  font-family: textfont;
  text-align: center;
  text-decoration: none;
  border: 1px dotted #069e97;
  color: #069e97;
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.button::before {
  content: "";
  position: absolute;
  top: 5px; /* Adjust for inlay position */
  left: 5px;
  right: 5px;
  bottom: 5px;
  border: 1.5px dotted #069e97; /* Inlay border style */
  opacity: 0; /* Hidden initially */
}

.button:hover::before {
  opacity: 1; /* Show inlay on hover */
}





.underline-on-hover {
  font-family: textitalicfont;
  font-size: 25px;
  text-decoration-style:dotted;
  transition: text-decoration 0.3s ease-in-out;
  color: #B2F8FE;
}

.underline-on-hover:hover {
  text-decoration-style: solid;
}


.button-container {
  display: flex;
  justify-content: center; /* Align buttons horizontally in the center */
  gap: 1rem; /* Add spacing between buttons */
  padding-top: 10vh;
  padding-bottom: 20vh;

}


.about-content {
  display: flex;
  align-items: center; /* Vertically center content */
  justify-content: space-between; /* Create space between image and text */
}

.accordion-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5vh;
  padding-bottom: 10vh;
  text-align: left; /*centers the buttons*/
}


/* .frame-container {
  width: 95%;
  margin: 0 auto;
  padding: 20px;
  border: 1.5px dotted #069e97;
  position: fixed; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  z-index: 1; 
  overflow-y: auto; 
  max-height: 90%; 
} */


/*      SCROLLBAR       */
/* For Firefox */
/* Change the thumb (the draggable part) color */
::-webkit-scrollbar-thumb {
  background-color: #069e97;
}

/* Change the track (the background) color */
::-webkit-scrollbar-track {
  background-color: #004336;
}

/* For Webkit browsers like Chrome and Safari */
/* Change the scrollbar width and color */
::-webkit-scrollbar {
  width: 10px; /* Adjust the width as needed */
}

::-webkit-scrollbar-thumb {
  background-color: #069e97;
}

::-webkit-scrollbar-track {
  background-color: #004336;
}


/* FOR MOBILE */
@media (max-width: 768px) {
  h1 {
    font-size: 40px; /* Adjust the font size for smaller screens */
  }

  nav ul {
    flex-direction: column; /* Stack items vertically for smaller screens */
    align-items: center; /* Center items vertically */
  }

  nav ul li {
    padding: 1px; /* Reduce padding for smaller touch targets */
  }

  .name {
    font-size: 7rem; /* Adjust as needed for mobile */
  }

  .button-container {
  flex-direction: column;
  align-items: center; /* Center buttons vertically */
  }
}